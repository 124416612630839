import React from 'react'
const PreloaderStateContext = React.createContext()
const PreloaderDispatchContext = React.createContext()
function preloaderReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_LOADING': {
      return { ...state, preloaded: !state.preloaded }
    }
    case 'START_LOADING': {
      return { ...state, preloaded: true }
    }
    case 'FINISH_LOADING': {
      return { ...state, preloaded: false }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function PreloaderProvider({ children }) {
  const [state, dispatch] = React.useReducer(preloaderReducer, { preloaded: true })
  return (
    <PreloaderStateContext.Provider value={state}>
      <PreloaderDispatchContext.Provider value={dispatch}>
        {children}
      </PreloaderDispatchContext.Provider>
    </PreloaderStateContext.Provider>
  )
}
function usePreloaderState() {
  const context = React.useContext(PreloaderStateContext)
  if (context === undefined) {
    throw new Error('usePreloaderState must be used within a PreloaderProvider')
  }
  return context
}
function usePreloaderDispatch() {
  const context = React.useContext(PreloaderDispatchContext)
  if (context === undefined) {
    throw new Error('usePreloaderDispatch must be used within a PreloaderProvider')
  }
  return context
}
export { PreloaderProvider, usePreloaderState, usePreloaderDispatch }