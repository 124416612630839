import React from 'react'
const GlobeloaderStateContext = React.createContext()
const GlobeloaderDispatchContext = React.createContext()
function globeloaderReducer(state, action) {
  switch (action.type) {
    case 'INCREMENT': {
      return { ...state, step: state.step + 1 }
    }
    case 'FINISH': {
      return { ...state, step: state.maxStep }
    }
    case 'RESET': {
      return { ...state, step: 0 }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function GlobeloaderProvider({ children }) {
  const [state, dispatch] = React.useReducer(globeloaderReducer, { maxStep: 2, step: 0 })
  return (
    <GlobeloaderStateContext.Provider value={state}>
      <GlobeloaderDispatchContext.Provider value={dispatch}>
        {children}
      </GlobeloaderDispatchContext.Provider>
    </GlobeloaderStateContext.Provider>
  )
}
function useGlobeloaderState() {
  const context = React.useContext(GlobeloaderStateContext)
  if (context === undefined) {
    throw new Error('useGlobeloaderState must be used within a GlobeloaderProvider')
  }
  return context
}
function useGlobeloaderDispatch() {
  const context = React.useContext(GlobeloaderDispatchContext)
  if (context === undefined) {
    throw new Error('useGlobeloaderDispatch must be used within a GlobeloaderProvider')
  }
  return context
}
export { GlobeloaderProvider, useGlobeloaderState, useGlobeloaderDispatch }