import React from 'react'
const AgreementStateContext = React.createContext()
const AgreementDispatchContext = React.createContext()
function agreementReducer(state, action) {
  switch (action.type) {
    case 'ACCEPT_TERMS': {
      return { ...state, popupTermsStatus: 'accepted' }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function AgreementProvider({ children }) {
  const isSSR = typeof window === "undefined";
  const [state, dispatch] = React.useReducer(agreementReducer, { popupTermsStatus: isSSR ? false : window.localStorage.getItem('bh-disclosureNoticesStatus') })
  return (
    <AgreementStateContext.Provider value={state}>
      <AgreementDispatchContext.Provider value={dispatch}>
        {children}
      </AgreementDispatchContext.Provider>
    </AgreementStateContext.Provider>
  )
}
function useAgreementState() {
  const context = React.useContext(AgreementStateContext)
  if (context === undefined) {
    throw new Error('useAgreementState must be used within a AgreementProvider')
  }
  return context
}
function useAgreementDispatch() {
  const context = React.useContext(AgreementDispatchContext)
  if (context === undefined) {
    throw new Error('useAgreementDispatch must be used within a AgreementProvider')
  }
  return context
}
export { AgreementProvider, useAgreementState, useAgreementDispatch }