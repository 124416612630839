// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-due-diligence-videos-index-js": () => import("./../../../src/pages/due-diligence-videos/index.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-index-js" */),
  "component---src-pages-due-diligence-videos-introduction-to-bhal-capital-allocation-js": () => import("./../../../src/pages/due-diligence-videos/introduction-to-bhal-capital-allocation.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-introduction-to-bhal-capital-allocation-js" */),
  "component---src-pages-due-diligence-videos-introduction-to-bhal-js": () => import("./../../../src/pages/due-diligence-videos/introduction-to-bhal.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-introduction-to-bhal-js" */),
  "component---src-pages-due-diligence-videos-introduction-to-bhmf-js": () => import("./../../../src/pages/due-diligence-videos/introduction-to-bhmf.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-introduction-to-bhmf-js" */),
  "component---src-pages-due-diligence-videos-introduction-to-brevan-howard-js": () => import("./../../../src/pages/due-diligence-videos/introduction-to-brevan-howard.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-introduction-to-brevan-howard-js" */),
  "component---src-pages-due-diligence-videos-introduction-to-pass-through-fee-js": () => import("./../../../src/pages/due-diligence-videos/introduction-to-pass-through-fee.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-introduction-to-pass-through-fee-js" */),
  "component---src-pages-due-diligence-videos-risk-management-ethos-js": () => import("./../../../src/pages/due-diligence-videos/risk-management-ethos.js" /* webpackChunkName: "component---src-pages-due-diligence-videos-risk-management-ethos-js" */),
  "component---src-pages-videos-old-js": () => import("./../../../src/pages/videos-old.js" /* webpackChunkName: "component---src-pages-videos-old-js" */),
  "component---src-pages-webinars-index-js": () => import("./../../../src/pages/webinars/index.js" /* webpackChunkName: "component---src-pages-webinars-index-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-culture-js": () => import("./../../../src/templates/culture.js" /* webpackChunkName: "component---src-templates-culture-js" */),
  "component---src-templates-digital-assets-js": () => import("./../../../src/templates/digital-assets.js" /* webpackChunkName: "component---src-templates-digital-assets-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-notices-js": () => import("./../../../src/templates/notices.js" /* webpackChunkName: "component---src-templates-notices-js" */),
  "component---src-templates-standard-videos-js": () => import("./../../../src/templates/standard-videos.js" /* webpackChunkName: "component---src-templates-standard-videos-js" */),
  "component---src-templates-text-page-js": () => import("./../../../src/templates/text-page.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-trader-example-js": () => import("./../../../src/templates/trader-example.js" /* webpackChunkName: "component---src-templates-trader-example-js" */),
  "component---src-templates-video-iframe-js": () => import("./../../../src/templates/video-iframe.js" /* webpackChunkName: "component---src-templates-video-iframe-js" */),
  "component---src-templates-videos-template-js": () => import("./../../../src/templates/videos-template.js" /* webpackChunkName: "component---src-templates-videos-template-js" */)
}

